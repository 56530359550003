.law {
    font-size: 20px;
    line-height: 300%;
    margin: 0 260px;
}

.law h1 {
    text-align: center;
    font-weight: bolder;
    font-size: 32px;
}

.law h3 {
    text-align: center;
    font-weight: bolder;
    padding-top: 20px;
}