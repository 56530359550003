@import "../../../utils/font.css";


.block-table .ant-table-container table>thead>tr:first-child th:first-child {
    text-align: left;
}

.block-table .ant-table-tbody>tr>td:first-child {
    text-align: left;
    padding: 16px 0;
}

.block-table .ant-table-tbody>tr>td {
    letter-spacing: 2px;
}

.block-table .ant-table-tbody>tr>td:last-child {
    width: 20%;
    text-align: right;
    padding: 16px 0;
}

.blockDetail {
    /* padding-top: 123px; */
}

.item {
    margin: 40px 260px;
    background: #ffffff;
}



.ant-page-header {
    border-bottom: 4px solid #FAFBFD;
}

.ant-page-header-content {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 33px;
    letter-spacing: 2px;
}

.title-icon {
    width: 28px;
    height: 28px;
    margin-right: 2px;
}

.item-container {
    padding: 43px 40px;
}

.blockDetail-text {
    display: flex;
    align-items: center;
    padding: 23px 0;
}

.blockDetail-text span:first-child {
    width: 110px;
    margin-right: 86px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
    letter-spacing: 1px;
}

.blockDetail-text span:last-child,
.blockDetail-text p {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: DIN;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.95);
    line-height: 25px;
    letter-spacing: 1.5px;
}

.blockDetail-text p {
    line-height: 30px;
}

.blockDetail-text img {
    width: 24px;
    height: 24px;
    margin-left: 16px;
}

.blockDetail-table .ant-table-thead>tr>th {
    border: none;
}

.blockDetail-table .ant-table-container table>thead>tr:first-child th:first-child {
    width: 30%;
}

.blockDetail-table .ant-table-tbody>tr>td:first-child {
    text-align: left;
    letter-spacing: 1px;
}

.blockDetail-table .ant-table-tbody>tr>td:nth-child(2) {
    letter-spacing: 2px;
}


.blockDetail-table .ant-table-tbody>tr>td:last-child {
    letter-spacing: 2px;
}