.idfor-table .ant-table-container table>thead>tr:first-child th:first-child {
    width: 36%;
}

.idfor-table .ant-table-tbody>tr>td:first-child {
    text-align: left;
    padding: 16px 0;
    letter-spacing: 1px;
}

.idfor-table .ant-table-tbody>tr>td:nth-child(2) {
    letter-spacing: 0.5px;
}

.idfor-table .ant-table-tbody>tr>td:nth-child(3) {
    letter-spacing: 2px;
}

.idfor-table .ant-table-tbody>tr>td:nth-child(4) {
    letter-spacing: 1.6px;
}

.idfor-table .ant-table-tbody>tr>td:nth-child(5) {
    width: 16%;
    text-align: right;
    padding: 16px 0;
    letter-spacing: 2px;
}

/* 数字身份详情 */
/*.idforDetail {
    position: relative;
}*/

.idforDetail-content {
    /* padding-top: 153px; */
    /* min-height: 100vh; */
    /* border: 1px solid red; */
    /* margin-bottom: 40px; */
}

.idforDetail-text {
    margin-top: 28px;
    display: flex;
    align-items: center;
}

.idforDetail-text:first-child {
    margin-top: 0;
}

.idforDetail-text span:first-child {
    width: 150px;
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
    letter-spacing: 1px;
}

.idforDetail-text span:last-child {
    font-size: 20px;
    font-family: DIN-Regular, DIN;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.95);
    line-height: 25px;
    letter-spacing: 1.5px;
}

.idforDetail-content .item:last-child .ant-page-header-content {
    font-size: 20px;
}

.idforDetail-content .ant-table-thead>tr>th {
    background: transparent;
}



.idforDetail-table .ant-table-thead>tr>th {
    border-bottom: 2px solid transparent;
}

.idforDetail-table .ant-table-container table>thead>tr:first-child th:first-child {
    width: 40%;
}

.idforDetail-table .ant-table-container table>thead>tr:first-child th:last-child {
    width: 20%;
}

.idforDetail-table .ant-table-tbody>tr>td:first-child {
    letter-spacing: 1px;
}

.idforDetail-table .ant-table-tbody>tr>td:nth-child(2) {
    letter-spacing: 2px;
}

.idforDetail-table .ant-table-tbody>tr>td:last-child {
    text-align: right;
    letter-spacing: 2px;
}