@import "../../../utils//font.css";

.transaction-table .ant-table-container table>thead>tr:first-child th:first-child {
    width: 60%;
}

.transaction-table .ant-table-container table>thead>tr:first-child th:nth-child(2) {
    width: 20%;
}

.transaction-table .ant-table-container table>thead>tr:first-child th:nth-child(3) {
    width: 20%;
}

.transaction-table .ant-table-tbody>tr>td:first-child {
    text-align: left;
    padding: 16px 0;
    letter-spacing: 0.5px;
}

.transaction-table .ant-table-tbody>tr>td:nth-child(3) {
    text-align: right;
    padding: 16px 0;
    letter-spacing: 2px;
}

.transaction-table .ant-table-tbody>tr>td:nth-child(2) {
    letter-spacing: 2px;
}

.transactionDetail {
    min-height: calc(100vh - 260px);
}

.transactionDetail-content {
    /* padding-top: 173px; */
}

.transactionDetail .ant-page-header-content {
    display: flex;
    align-items: center;
}

.transactionDetail .ant-page-header {
    border-bottom: 4px solid #FAFBFD;
}

.detail-container {
    padding: 40px;
}

.detail-container p {
    padding-top: 23px;
    display: flex;
    align-items: center;
}

.detail-container p:first-child {
    padding-top: 0;
}

.detail-container p span:first-child {
    width: 150px;
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
    letter-spacing: 1px;
}

.detail-container p span:last-child {
    font-size: 20px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.95);
    line-height: 25px;
    letter-spacing: 1.5px;
    font-family: DIN;
}

.transaction-success {
    color: #00A0E8 !important;
}